import React from 'react';

const Form = ({ children, handler }) => {
  return (
    <form className="dc-form" onSubmit={(e) => handler(e)}>
      {children}
    </form>
  )
};

export default Form;