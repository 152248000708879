import React, { useContext } from "react";
import cs from 'classnames';
import { ArrowDown, Tick } from "../icons";
import Select, { Option } from 'rc-select';
import { MainContext } from "../ctx/main";

const placeholderRequired = (placeholder, required) => {
  return `${placeholder} ${required ? '*' : ''}`;
}

const Text = ({ placeholder, value, onChange, required }) => {
  return (
    <input type="text" placeholder={placeholderRequired(placeholder, required)} value={value} onChange={onChange} />
  )
}

const TextArea = ({ placeholder, value, onChange, required }) => {
  return (
    <textarea rows={5} type="text" placeholder={placeholderRequired(placeholder, required)} value={value} onChange={onChange} />
  )
}

const Checkbox = ({ value, id, onChange, title, required }) => {
  return (
    <div className="dc-form-group">
      <div tabIndex="0" className={cs("dc-checkbox", { 'dc-checkbox-selected': value })} role="button" onClick={() => onChange({ target: { value: !value } })} >
        <Tick />
      </div>
      <input type="checkbox" id={id} checked={value} onChange={() => onChange({ target: { value: !value } })} />
      <label htmlFor={id}> {title}  {required ? '*' : ''} </label>
    </div>
  )
}

const Dropdown = ({ placeholder, value, id, required, onChange, options }) => {
  const { setDisabled, setScrolling } = useContext(MainContext);
  const handler = (value) => {
    onChange({ target: { value } });
  }
  const toggleOpen = (state) => {
    const isMobile = typeof window !== 'undefined' && window.innerWidth <= 1024;
    if (!isMobile) {
      setScrolling(false);
      setDisabled(state);
    }
  }

  return (
    <div className="dc-select">
      <Select
        onFocus={() => toggleOpen('forced')}
        onBlur={() => toggleOpen(false)}
        placeholder={placeholderRequired(placeholder, required)}
        value={value}
        showSearch={false}
        onChange={handler}
        inputIcon={<ArrowDown className="input-arrow" />}
      >
        {options.map(opt => <Option key={opt} value={opt}>{opt}</Option>)}
      </Select>
    </div>
  )
}

const types = {
  text: Text,
  textarea: TextArea,
  checkbox: Checkbox,
  dropdown: Dropdown
};

const Input = ({ type, error, ...rest }) => {
  const Component = types[type];
  if (!Component) {
    return null;
  }
  return <div className={cs("input", { 'input-error': error })}><Component {...rest} /> {error && <div className="input-error-msg">{error.error}</div>}</div>
}

export default Input;