import React from 'react';
import Section from "../Section";
import scaleyou from '../../images/scale_you_bg.jpg';

import Blinkist from '../../images/logos/Blinkist.svg';
import Deezer from '../../images/logos/Deezer.svg';
import Fairtrade from '../../images/logos/Fairtrade.svg';
import Omio from '../../images/logos/Omio.svg';
import Roku from '../../images/logos/Roku.svg';
import Truecaller from '../../images/logos/Truecaller.svg';
import VanMoof from '../../images/logos/VanMoof.svg';
import Vinted from '../../images/logos/Vinted.svg';
import Winzo from '../../images/logos/Winzo.svg';
import Wooga from '../../images/logos/Wooga.svg';

const logos = [
  Roku,
  VanMoof,
  Truecaller,
  Deezer,
  Vinted,
  Blinkist,
  Wooga,
  Omio,
  Winzo,
  Fairtrade,
];

const Plateau = () => {

  return (
    <Section backgroundColor="transparent" backgroundImage={scaleyou} className="dc-section-notpadded dc-bg-top dc-plateau">
      <div>
        <h2>
          We know how hard it is to hit the <br className='br' /> growth plateau.
          We’re here to help you <br className='br' /> break through.
        </h2>
        <h3>
          400+ digital brands grown to date
        </h3>

        <div className='dc-logos'>
          {logos.map((logo, i) => <div key={i}><img src={logo} /></div>)}
        </div>
      </div >
    </Section >)
};
 
export default Plateau;