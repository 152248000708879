import React from 'react';
import Section from "../Section";
import ContactForm from '../forms/ContactForm';

const Contact = () => {
  return (
    <Section className="dc-section-notpadded dc-section-alt dc-section-contact" backgroundColor={'#FF6BAB'}>
      <div id="last" className='dc-section-content'>
        <div className="dc-section-head">
          <h2>
            The Brandformance Score 
            <br />
            shows your strengths — and your pain points.
          </h2>

          <ContactForm />

        </div>
      </div>
    </Section>
  )
};

export default Contact;