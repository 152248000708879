import React, { useEffect, useState } from "react"
import Hero from "../components/Sections/Hero";
import Waste from "../components/Sections/Waste";
import Template from '../layout/Template';
import Contact from "../components/Sections/Contact";
import Plateau from "../components/Sections/Plateau";
import debounce from 'lodash.debounce';

import Slider from "react-slick";
import '../layout/slick.scss';
import useWindowSize from "../hooks/useResize";
import { MainContext } from "../components/ctx/main";
import ScaleYou from "../components/Sections/ScaleYou";
import HowToScale from "../components/Sections/HowToScale";

const SLIDER_LENGTH = 6;
const OTHER_SLIDER = 3;
const OTHER_SLIDER_LENGTH = 3;


// markup
const IndexPage = () => {
  const [disabled, setDisabled] = useState(false)
  const size = useWindowSize();
  //  both current and currentRef are necessary.
  // one is for components and the other one is for handlers
  const [current, setCurrent] = useState(0);
  const ref = React.useRef();
  const subRef = React.useRef();
  const currentRef = React.useRef(0);
  const subCurrentRef = React.useRef(0);

  const setScrolling = (state) => {
    if (disabled === 'forced') return;
    if (state) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
      setDisabled(true);
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
      setDisabled(false);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    draggable: false,
    beforeChange: (current) => {
      if (current === SLIDER_LENGTH) {
        currentRef.current = current
        setCurrent(current);

      }
    },
    afterChange: (current) => {
      currentRef.current = current
      setCurrent(current);
      if (current !== SLIDER_LENGTH - 1) {
        setScrolling(false);
      }

    }
  };


  const goTo = React.useCallback((idx) => {
    let id = idx;
    if (typeof idx === 'string') {
      const maping = {
        'last': 8,
        'first': 0,
      };
      const mapped = maping[idx];
      if (mapped !== undefined) {
        id = mapped;
      }
    }
    if (ref.current) {
      ref.current.slickGoTo(id)
    } else {
      const elem = document.getElementById(idx)
      const offset = elem.getBoundingClientRect().top;
      const currentTop = document.documentElement.scrollTop || document.body.scrollTop;

      window.scroll({
        top: offset + currentTop,
        behavior: 'smooth'
      });
    }
  }, []);

  const triggerSlides = (delta) => {
    const outsideOther = delta > 0 ? subCurrentRef.current === OTHER_SLIDER_LENGTH - 1 : subCurrentRef.current === 0;
    const other = !outsideOther && currentRef.current === OTHER_SLIDER
    const width = window.innerWidth;
    if (width < 1024) {
      return;
    }

    if (!other) {
      if (delta < 0) {
        ref.current.slickPrev();
      } else {
        ref.current.slickNext();

      }
    } else if (subRef.current) {
      if (delta < 0) {
        subRef.current.slickPrev();
      } else {
        subRef.current.slickNext();
      }
    }
  }

  const debouncedCallback = debounce(triggerSlides, 1, { leading: true });

  let delta = 0;
  const threshold = 5;

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    if (window.location.hash === '#book-demo') {
      goTo(SLIDER_LENGTH);
      window.location.hash = '';
      window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
    }
    const evt = (e) => {
      if (disabled === 'forced') return;
      if (typeof window === "undefined") return;
      if (disabled && window.scrollY < 80 & e.deltaY < 0) {
        setScrolling(false);
      }
      if (disabled) return;
      if (e.deltaY === 0) {
        delta = 0;
      }

      // const threshold = e.deltaY < mainThreshold ? 5 : mainThreshold;
      if (e.deltaY < 0) {
        if (e.deltaY + threshold >= delta) {
          delta = e.deltaY;
          return;
        }
      } else if (e.deltaY >= 0) {
        if (e.deltaY - threshold <= delta) {
          delta = e.deltaY;
          return;
        }
      }
      if (delta === 0 || (e.deltaY > 0 && delta < e.deltaY) || (e.deltaY < 0 && delta > e.deltaY)) {
        delta = e.deltaY;
      }
      if (currentRef.current === SLIDER_LENGTH - 1 & e.deltaY > 0) {
        setScrolling(true);
      }

      debouncedCallback(e.deltaY);
    }
    if (ref.current) {
      if (typeof window === "undefined") return;
      window.addEventListener('wheel', evt)
    }
    return () => {
      if (typeof window === "undefined") return;
      window.removeEventListener('wheel', evt)
    }
  }, [disabled]);

  useEffect(() => {
    const evt = function (e) {
      const tab = ['Tab', 9];
      const key = e.key || e.keyCode;
      const next = [39, 40, 'ArrowRight', 'ArrowDown', 'Down', 'Right', ...tab];
      const prev = [38, 37, 'ArrowLeft', 'ArrowUp', 'Left', 'Up'];
      const isNext = next.includes(key);
      const isPrev = prev.includes(key);
      const delta = isNext && 1 || isPrev && -1 || 0;
      const isLast = current + delta === SLIDER_LENGTH;
      const isTabAtLast = tab.includes(key) && isLast;

      if (disabled) {
        return;
      }
      if (isNext || isPrev) {

        if (!isTabAtLast) {
          e.preventDefault();
          triggerSlides(delta);

          if (isLast) {
            setScrolling(true);
          }
        }
      }
    }
    if (typeof window !== 'undefined' && window.innerHeight > 1024) {
      document.addEventListener('keydown', evt);
    }


    return () => {
      document.removeEventListener('keydown', evt)
    }
  }, [current, disabled]);

  return (
    <MainContext.Provider value={{ disabled, setDisabled, setScrolling }}>
      <Template current={current} goTo={goTo} >
        {(size.width < 1024) ? (
          <>
            <Hero goTo={goTo} />
            <Waste goTo={goTo} />
            <ScaleYou goTo={goTo} />
            <HowToScale refCb={subRef} refCurrent={subCurrentRef} goTo={goTo} />
            <Plateau />
            <Contact />
          </>
        ) : (
          <Slider ref={ref} {...settings}>
            <Hero goTo={goTo} />
            <Waste goTo={goTo} />
            <ScaleYou goTo={goTo} />
            <HowToScale refCb={subRef} refCurrent={subCurrentRef} goTo={goTo} />
            <Plateau />
            <Contact />

          </Slider>

        )}
      </Template >
    </MainContext.Provider>
  )
}

export default IndexPage
