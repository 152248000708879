import React from 'react';

const CornerBadge = ({ text }) => {
  return <div className='dc-corner-badge-container'>
    <div className='dc-corner-badge-content'>
      {text}
    </div>
  </div>
}

export default CornerBadge;