import React from 'react';
import cs from 'classnames';
import Animate from './Animate';

export const GRID_VARIANT = {
  large: 'large'
};

const GridFragment = ({ Icon, text, title, i }) => (
  <Animate className="dc-grid-elem" delay={(i + 1) * 100}>
    <div>
      {title && <div className='dc-grid-title'>{title}</div>}
      {Icon && <Icon />}
      <div className="dc-grid-text">{text}</div>
    </div>
  </Animate>
);

const Grid = ({ data, variant }) => {
  return (
    <div className={cs("dc-grid", { 'dc-grid-large': variant === GRID_VARIANT.large })}>
      {data.map((elem, i) => <GridFragment key={i} {...elem} i={i} />)}
    </div>
  )
}

export default Grid;