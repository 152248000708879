const errormessages = {
  'required': 'Oops, you missed a field there.',
  'generic': 'Field invalid.'
};

// See http://rosskendall.com/blog/web/javascript-function-to-check-an-email-address-conforms-to-rfc822
const isEmail = (email) => {
	return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test( email );	
}

const validators = {
  'email': isEmail, 
}

export const validate = (fields, schema) => {
  const invalidFields = schema.map(field => {
    const isRequired = field.required;

    if (isRequired && !fields[field.name]) {
      return { name: field.id, error: field.errorMessage || errormessages.required };
    }
    if (field.validate && validators[field.validate]) {
      return validators[field.validate](fields[field.name]) ? false : { name: field.name, error: field.errorMessage || errormessages.generic };
    }
    return false;
  }).filter(elem => elem)

  return invalidFields;
}