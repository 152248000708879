import React from 'react';
import Section from "../Section";
import Button from '../forms/Button';
import wasteBg from '../../images/waste_bg.jpg';

const Waste = ({ goTo }) => {

  return (
    <Section className="dc-section-padded dc-section-waste" >
      <div className='dc-section-sub' style={{ backgroundImage: `url(${wasteBg})` }}>
        <div className='dc-section-content'>
          <div className="dc-section-head">
            <h2>Siloing your marketing <br />
              is bad for business.
            </h2>
            <h3>It’s as simple as that. <br />
              With Brandformance, you can understand how all your marketing <br className='br' />
              activities work together, measure their combined impact and find new <br className='br' />
              opportunities for growth. </h3>
            <Button label="GET YOUR CONSULTATION" outline variant="white" action={() => goTo('last')} />

          </div>
        </div>
      </div>
    </Section>
  )
};

export default Waste;