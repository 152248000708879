import React from 'react';
import Section from "../Section";
import scaleyou from '../../images/scale_you_bg.jpg';
import Button from '../forms/Button';

const ScaleYou = ({ goTo }) => {

  return (
    <Section backgroundColor="transparent" backgroundImage={scaleyou} className="dc-section-notpadded dc-bg-top" >
      <div>
        <h2>
          We know you want to scale <br className='br' />
          as fast as possible
        </h2>
        <h3>
          To do that, you need to know exactly how to spend your marketing budget. But it can be difficult to measure the impact of all your combined marketing activities, which makes you uncertain about whether you are investing where it really counts.
          <br />
          <br />
          This is why we’ve created Brandformance: to help you understand how all your marketing activities work together, measure their combined impact, and find new opportunities for growth.
        </h3>
        <div className='spaced-mobile'>
          <Button label="GET YOUR CONSULTATION" action={() => goTo('last')} />
        </div>
      </div>
    </Section>
  )
};

export default ScaleYou;