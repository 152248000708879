export const submit = (target, values) => {
  const data = new FormData()

  Object.keys(values).forEach(name => {
    data.append(name, values[name])
  })

  return fetch(target.action, {
    method: 'POST',
    body: data,
  });
}
