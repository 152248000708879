import React, { useState } from "react";
import cs from "classnames";
import { Heart } from "../icons";
import Button from './Button';
import Form from "./Form";
import Input from "./Input";
import { submit } from "./submit";
import { validate } from "./utils";
import Animate from "../Animate";
import { LinkedinEvents } from "../analytics/Linkedin";

const hiddenFields =
{
  'xnQsjsdp': 'ab7db38d5efbf0b257123858c9b960c1f10d4e54011dc2ab6ecb48142704f733',
  'zc_gad': '',
  'actionType': 'Q29udGFjdHM=',
  'xmIwtLD': 'fb1651d61ff3456d40f93e8a907dc7967f4906e543f36ccc5711244755eab90f'
  // 'returnURL': '', 
};

const formScheme = [
  {
    id: 'First_Name',
    name: 'First Name',
    placeholder: 'First Name',
    type: 'text',
    required: true
  },
  {
    id: 'Last_Name',
    name: 'Last Name',
    placeholder: 'Last Name',
    type: 'text',
    required: true
  },
  {
    id: 'Account_Name',
    name: 'Account Name',
    placeholder: 'Company',
    type: 'text',
    required: true
  },
  {
    id: 'Email',
    name: 'Email',
    placeholder: 'Company Email',
    type: 'text',
    required: true,
    validate: 'email',
    errorMessage: 'Please provide valid email.'
  },
  {
    id: 'Title',
    name: 'Title',
    placeholder: 'Job Title',
    type: 'text',
  },
  {
    id: 'Mailing_Country',
    name: 'Mailing Country',
    placeholder: 'Country',
    type: 'text',
    required: true
  },
  {
    id: 'CONTACTCF31',
    name: 'CONTACTCF31',
    placeholder: 'Company Size',
    type: 'dropdown',
    required: true,
    options: [
      '2 to 10 employees',
      '11 to 50 employees',
      '51 to 200 employees',
      '201 to 500 employees',
      '501+ employees',
    ]
  },
  {
    id: 'CONTACTCF16',
    name: 'CONTACTCF16',
    placeholder: 'Industry',
    type: 'dropdown',
    options: [
      'Auto & Vehicles',
      'Baby & Children',
      'Business Services',
      'Deals, Cashback & Price Comparison',
      'Events & Entertainment',
      'Fashion, Shoes & Accessories',
      'Financial services & Insurance',
      'Food & Beverages',
      'Gambling',
      'Gaming',
      'Gift, Flowers & Handcraft',
      'Health, Beauty & Care',
      'Home & Furniture',
      'Online shopping',
      'Printing & Photography',
      'Services around your home',
      'Social networks & Dating',
      'Sports, Outdoor & Fitness',
      'Travel',
      'Other'
    ]
  },
  {
    name: 'Description',
    id: 'Description',
    placeholder: 'Anything else you want to tell us?',
    type: 'textarea',
  }
];

const checkboxesSchema = [
  {
    id: 'privacy',
    name: 'privacy',
    type: 'checkbox',
    title: <>I have read and accept DCMN&apos;s <a href="/privacy" rel="noreferrer" target="_blank">Privacy Policy.</a></>,
    required: true,
    errorMessage: 'Please accept Privacy Policy',
    include: false,
  },
  {
    name: 'CONTACTCF104',
    id: 'CONTACTCF104',
    type: 'checkbox',
    title: <>I agree to receiving updates on the latest marketing whitepapers, growth tips, event invitations and other info.</>
  }
]

const ContactForm = () => {
  const [status, setStatus] = useState('ready');
  const [form, setForm] = useState(hiddenFields);
  const [errors, setErrors] = useState([]);

  const updateField = (name) => (e) => {
    const newVal = {
      ...form,
      [name]: e.target.value,
    };

    if (errors.length > 0) {
      const invalidFields = validate(newVal, [...formScheme, ...checkboxesSchema]);
      setErrors(invalidFields);
    }

    setForm(newVal);
  };

  const formAction = (e) => {
    e.preventDefault();
    const invalidFields = validate(form, [...formScheme, ...checkboxesSchema]);

    if (invalidFields.length === 0) {
      const config = {
        action: 'https://crm.zoho.com/crm/WebToContactForm'
      };

      const formData = removeNotIncluded(form, [...formScheme, ...checkboxesSchema]);
      setStatus('loading');

      submit(config, formData)
        .then(() => {
          LinkedinEvents.formConversion();
          setStatus('success');
          setForm(hiddenFields)
        })
        .catch(() => {
          setStatus('error');
          setTimeout(() => {
            setStatus('ready');
          }, 3000);
        }).then(() => {
          if (typeof window !== 'undefined') {
            document.getElementById('book-demo').scrollIntoView()
          }
        })
    } else {
      setErrors(invalidFields);
    }
  }

  const getError = (invalidFields, fieldId) => invalidFields.find(elem => elem.name === fieldId);

  const removeNotIncluded = (form, scheme) => {
    const toRemove = scheme.filter(elem => elem.include !== undefined && !elem.iclude);
    const newForm = { ...form };

    Object.keys(form).forEach(key => {
      const found = toRemove.findIndex(elem => elem.name === key);
      if (found > -1) {
        delete newForm[key];
      }
    });

    return newForm;
  }

  return (
    <div id="book-demo">
      <h3>
        The Score benchmarks all your combined marketing activities: performance and brand, creative and media. Get in touch to learn about a Brandformance Score for your brand.
      </h3>
      <Form handler={formAction}>
        <div className="dc-form-group">
          {formScheme.map(elem => <Input key={elem.id} {...elem} onChange={updateField(elem.name)} value={form[elem.name]} error={getError(errors, elem.id)} />)}
        </div>
        <div className="dc-form-group-column">
          {checkboxesSchema.map(elem => <Input key={elem.id} {...elem} onChange={updateField(elem.name)} value={form[elem.name]} error={getError(errors, elem.id)} />)}
        </div>

        <Button label="Submit" outline variant="alt" />
      </Form>
      <Animate variant="fade" trigger={status === 'loading'} ignoreInView>
        <div className={cs("dc-cover-over", { 'dc-cover-open': status === 'error' })}>
          <div>
            <h1>Sending...</h1>
            <h3>
              Please hold on while we send the data.
            </h3>
          </div>
        </div>
      </Animate>
      <Animate variant="fade" trigger={status === 'success'} ignoreInView>
        <div className={cs("dc-cover-over", { 'dc-cover-open': status === 'success' })}>
          <div>
            <Heart />
            <h3>
              Thank you for your message! <br /> We will be in touch shortly.
            </h3>
          </div>
        </div>
      </Animate>
      <Animate variant="fade" trigger={status === 'error'} ignoreInView >
        <div className={cs("dc-cover-over", { 'dc-cover-open': status === 'error' })}>
          <div>
            <h1>Oops!</h1>
            <h3>
              Something went wrong, <br /> please try again later.
            </h3>
          </div>
        </div>
      </Animate>

    </div>
  )
}

export default ContactForm