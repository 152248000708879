import React from "react";

export const LinkedinEvents = {
  formConversion: () => {
    if (typeof window !== 'undefined' && window.lintrk) {
      window.lintrk('track', { conversion_id: 8630113 });
    }
  }
}

export const LinkedinPixel = () => (
  <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=26376&fmt=gif" />
);
