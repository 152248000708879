import React, { useState } from 'react';
import Slider from 'react-slick';
import { Arrow, Call } from './icons';
import screen1 from '../images/dc-screen-1.png';
import bg1 from '../images/1_bg.jpg';
import bg2 from '../images/2_bg.jpg';
import bg3 from '../images/3_bg.jpg';
import Button from './forms/Button';
import CornerBadge from './CornerBadge';
import Animate from './Animate';
import cs from 'classnames';
import Section from './Section';

const HowToScaleSlider = ({ refCb, refCurrent, goTo }) => {
  const [current, setCurrent] = useState(0);
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 1024
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    arrows: false,
    fade: false,
    swipeToSlide: true,
    beforeChange: () => {
      setCurrent(-1);
    },
    afterChange: (currentSlide) => {
      refCurrent.current = currentSlide;
      setCurrent(currentSlide);
      // there's a bug in slick that doesn't show last slide for some reason, so we've got empty slide as last one
      if (currentSlide > 2) {
        setTimeout(() => {
          refCb.current.slickGoTo(2);
        }, 10);
      }
    }
  };

  const nextSlide = () => {
    if (refCb.current) {
      refCb.current.slickNext();
    }
  }

  return (
    <div className='how-it-works'>
      <CornerBadge text={<>How<br /> it works</>} />
      <div className={cs('sliderArrow', { 'sliderArrow-out': current >= 2 || current === -1 })} onClick={nextSlide}>
        <Arrow />
      </div>
      <Slider ref={refCb} {...settings}>
        <Section backgroundImage={bg1}>
          <div className='dc-section-content'>
            <div>
              <Animate trigger={current === 0} variant='fade'>
                <h2 className='color-alt'>
                  <b> 1. </b><br />
                  We analyse both your brand and <br /> performance marketing.
                </h2>
              </Animate>
              <div className='dc-spacer'> &nbsp; </div>
            </div>
          </div>
        </Section>
        <Section backgroundImage={bg2}>
          <div className='dc-section-content'>
            <div>
              <Animate trigger={current === 1} variant='fade'>
                <h2>
                  <b>2.</b> <br />
                  We calculate your Brandformance Score.
                </h2>
              </Animate>
              <div className='dc-spacer'> &nbsp; </div>
            </div>
          </div>
        </Section>
        <Section backgroundImage={bg3}>
          <div className='dc-section-content'>
            <div>
              <Animate trigger={current === 2} variant='fade'>
                <h2>
                  <b>3.</b> <br />
                  We find growth opportunities to help <br /> you scale.
                </h2>
              </Animate>
              <Button label="GET YOUR CONSULTATION" action={() => goTo('last')} />
            </div>
          </div>
        </Section>

        {typeof window !== 'undefined' && window.innerWidth > 1024 &&
          <div />
        }
      </Slider>
    </div>
  )
}

export default HowToScaleSlider;