import React from 'react';
import Section from "../Section";
import Button from '../forms/Button';
import heroBg from '../../images/hero_bg.jpg';
import { Cash, Impact, Outcomes } from '../icons';
import Grid from '../Grid';

const Hero = ({ goTo }) => {

  const grid = [
    {
      Icon: Outcomes,
      text: <>Drive growth <br />
        opportunities</>
    },
    {
      Icon: Cash,
      text: <>
        Invest <br />
        where it matters</>
    },
    {
      Icon: Impact,
      text: <>Measure <br /> your impact</>
    },
  ]

  return (
    <Section backgroundImage={heroBg}>
      <div id="first">
        <h1>
          Unleash the full potential <br />
          of your marketing
        </h1>
        <h3>
          For every fast-growing startup, growth eventually plateaus. <br />
          With our unique Brandformance<sup>&reg;</sup> methodology, you can measure all
          your marketing and find new opportunities to scale.
        </h3>
        <Button label="GET YOUR CONSULTATION" action={() => goTo('last')} />
      </div>

      <Grid data={grid} />
    </Section>
  )
};

export default Hero;