import React from 'react';
import Section from "../Section";
import HowToScaleSlider from '../HowToScaleSlider';

const HowToScale = ({ refCb, refCurrent, goTo }) => {
  return (
    <Section backgroundColor="transparent" className="dc-section-slider dc-bg-bottom" >
      <HowToScaleSlider refCb={refCb} refCurrent={refCurrent} goTo={goTo} />
    </Section>
  )
};

export default HowToScale;