import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';

const Animate = ({ children, delay = 0, trigger, variant, className, ignoreInView }) => {
  const [visible, setVisible] = useState(false);
  const [triggerVisible, setTriggerVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTriggerVisible(trigger);
    }, delay);
  }, [trigger, ignoreInView])

  const visibility = !visible ? false : (trigger !== undefined) ? triggerVisible : visible;
  const isVisible = ignoreInView ? triggerVisible : visibility

  const animationTypes = {
    'def': 'dc-animate-on',
    'right': 'dc-animate-right',
    'fade': 'dc-animate-fade',
  };

  const animationType = animationTypes[variant] || animationTypes['def'];
  if (typeof window !== 'undefined' && window.innerWidth <= 1024) {
    return (
      <div className={className}>
        {children}
      </div>
    )
  }


  return (
    <Waypoint onEnter={() => setTimeout(() => setVisible(true), delay)} onLeave={() => setVisible(false)}>
      <div className={classNames('dc-animate', animationType, className, { 'dc-animate-visible': isVisible })}>
        {children}
      </div>
    </Waypoint>)
}

export default Animate;